<template>
<div class="app-calendar overflow-hidden border">
    <b-card no-body>
        <b-card-header class="pb-50">
            <h5>
                Filtreleme
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row class="mb-2">

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedClass" :options="classes" class="w-100" :getOptionLabel="option => option.name" :reduce="val => val.id" />
                </b-col>

                <b-col cols="12" md="6" class="mb-md-0 mb-2">
                    <label>Alt Sınıf</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildClass" :options="childClasses" class="w-100" :reduce="val => val.id" :getOptionLabel="option => option.name" />
                </b-col>

                <b-col cols="12" md="12" class="mb-2 mt-2">
                    <b-button variant="success" block @click="getSyllabusData()">FİLTRELE</b-button>
                </b-col>

            </b-row>
            <b-overlay :show="loading" no-wrap>
            </b-overlay>
        </b-card-body>
    </b-card>
    <div class="row no-gutters">
        <!-- Calendar -->
        <div class="col position-relative">
            <div class="card shadow-none border-0 mb-0 rounded-0">
                <div class="card-body pb-0">
                    <full-calendar ref="calendarRef" :options="calendarOptions" class="full-calendar" />
                </div>
            </div>
        </div>
    </div>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Ders Kaydı</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Alt Sınıf" label-for="vue-select">
                    <v-select id="vue-select" v-model="addSyllabusRequest.classId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="childClasses" />
                </b-form-group>
                <!-- Code -->
                <b-form-group label="Öğretmen" label-for="vue-select">
                    <v-select id="vue-select" v-model="addSyllabusRequest.teacherId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" :getOptionLabel="option => option.firstName+' '+option.lastName" :options="teachers" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Ders" label-for="vue-select">
                    <v-select id="vue-select" v-model="addSyllabusRequest.lessonId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="lessons" />
                </b-form-group>

                <b-form-group label="Tarihi" label-for="name">
                    <flat-pickr v-model="addSyllabusRequest.date" class="form-control" :config="{ enableTime: false,time_24hr:true,weekNumbers:true,dateFormat: 'd-m-Y',locale:'tr'}" />
                </b-form-group>

                <b-form-group label="Başlangıç Saati" label-for="name">
                    <flat-pickr v-model="addSyllabusRequest.startTime" class="form-control" :config="{ enableTime: true,time_24hr:true,noCalendar:true,dateFormat: 'H:i',locale:'tr'}" />
                </b-form-group>

                <b-form-group label="Bitiş Saati" label-for="name">
                    <flat-pickr v-model="addSyllabusRequest.endTime" class="form-control" :config="{ enableTime: true,time_24hr:true,noCalendar:true,dateFormat: 'H:i',locale:'tr'}" />
                </b-form-group>

                <b-form-group label="Haftalık Tekrarlama" label-for="code">
                    <b-form-input id="code" type="number" v-model="addSyllabusRequest.repeatCount" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addSyllabus">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Ders Kaydı Güncelle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Alt Sınıf" label-for="vue-select">
                    <v-select id="vue-select" v-model="editSyllabusRequest.classId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="childClasses" />
                </b-form-group>
                <!-- Code -->
                <b-form-group label="Öğretmen" label-for="vue-select">
                    <v-select id="vue-select" v-model="editSyllabusRequest.teacherId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" :getOptionLabel="option => option.firstName+' '+option.lastName" :options="teachers" />
                </b-form-group>

                <!-- Name -->
                <b-form-group label="Ders" label-for="vue-select">
                    <v-select id="vue-select" v-model="editSyllabusRequest.lessonId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(clas) => clas.id" label="name" :options="lessons" />
                </b-form-group>

                <b-form-group label="Tarihi" label-for="name">
                    <flat-pickr v-model="editSyllabusRequest.date" class="form-control" :config="{ enableTime: false,time_24hr:true,weekNumbers:true,dateFormat: 'd-m-Y',locale:'tr'}" />
                </b-form-group>

                <b-form-group label="Başlangıç Saati" label-for="name">
                    <flat-pickr v-model="editSyllabusRequest.startTime" class="form-control" :config="{ enableTime: true,time_24hr:true,noCalendar:true,dateFormat: 'H:i',locale:'tr'}" />
                </b-form-group>

                <b-form-group label="Bitiş Saati" label-for="name">
                    <flat-pickr v-model="editSyllabusRequest.endTime" class="form-control" :config="{ enableTime: true,time_24hr:true,noCalendar:true,dateFormat: 'H:i',locale:'tr'}" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="editSyllabus">
                        Güncelle
                    </b-button>
                    <b-button variant="danger" class="mr-2" type="submit" @click="removeSyllabus">
                        Sil
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

</div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCardText,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BProgress,
    BOverlay,
    BSidebar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
export default {
    components: {
        flatPickr,
        FullCalendar,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCardText,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BProgress,
        BOverlay,
        vSelect,
        BSidebar
    },
    data() {
        return {
            calendar: {},
            loading: false,
            addPanel: false,
            editPanel: false,
            classes: [],
            childClasses: [],
            teachers: [],
            lessons: [],
            selectedClass: {},
            selectedChildClass: {},
            oldData: [],
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
                initialView: 'timeGridWeek',
                headerToolbar: {
                    start: 'sidebarToggle, prev,next, title',
                    end: 'timeGridWeek,listMonth',
                },
                editable: false,
                eventResizableFromStart: false,
                eventClick: this.handleEvent,
                eventClassNames: this.eventClassName,
                dragScroll: false,
                dayMaxEvents: 3,
                locale: 'tr',
                navLinks: true,
                rerenderDelay: 350,
                events: []
            },
            addSyllabusRequest: {
                parentClass: '',
                classId: '',
                lessonId: '',
                teacherId: '',
                date: '',
                startTime: '',
                endTime: '',
                repeatCount: 0
            },
            editSyllabusRequest: {
                id: '',
                parentClass: '',
                classId: '',
                lessonId: '',
                teacherId: '',
                date: '',
                startTime: '',
                endTime: ''
            }
        }
    },
    created() {
        this.getData();
        this.getTeacher();
        this.getLessons();
        this.$root.$on('AddButtonClick', () => {
            //console.log("Add Button Call");
            this.addPanel = true;
        });
    },
    watch: {
        selectedClass: function (val) {
            this.getChildClass(val);
        }
    },
    methods: {
        async getData() {
            this.loading = true;
            var classes = await this.$http.get("Branch/Classes");
            this.classes = classes.data.data;
            this.loading = false;
        },
        async getTeacher() {
            var users = await this.$http.get("Branch/Teachers");
            this.teachers = users.data.data;
        },
        async getLessons() {
            var lessons = await this.$http.get("Branch/Lessons");
            this.lessons = lessons.data.data;
        },
        async getSyllabusData() {
            this.loading = true;
            this.calendarOptions.events = [];
            var response = await this.$http.get("Branch/Syllabus/" + this.selectedChildClass);
            var api = this.$refs.calendarRef.getApi();
            this.clearAllEvents();
            this.oldData = response.data.data;
            response.data.data.forEach(element => {
                api.addEvent({
                    id: element.id,
                    title: element.lesson.name,
                    date: moment(element.date, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + element.startTime,
                    end: moment(element.date, "DD-MM-YYYY").format('YYYY-MM-DD') + 'T' + element.endTime,
                    create: true
                });
            });
            api.refetchEvents();
            api.render();
            this.loading = false;
        },
        clearAllEvents() {
            var api = this.$refs.calendarRef.getApi();
            var eventSource = api.getEvents();
            eventSource.forEach(event => {
                event.remove();
            });
        },
        async getChildClass(classId) {
            this.loading = true;
            var childClasses = await this.$http.get("Branch/Classes/" + classId);
            this.childClasses = childClasses.data.data;
            this.loading = false;
        },
        addSyllabus() {
            var request = new FormData();
            request.append("parentClass", this.addSyllabusRequest.parentClass);
            request.append("classId", this.addSyllabusRequest.classId);
            request.append("lessonId", this.addSyllabusRequest.lessonId);
            request.append("teacherId", this.addSyllabusRequest.teacherId);
            request.append("date", this.addSyllabusRequest.date);
            request.append("startTime", this.addSyllabusRequest.startTime);
            request.append("endTime", this.addSyllabusRequest.endTime);
            request.append("repeatCount", this.addSyllabusRequest.repeatCount);
            this.$http.post("Branch/AddSyllabus", request).then((data) => {
                    this.getSyllabusData();
                    this.addPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kullanıcı Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        editSyllabus() {

            var request = new FormData();
            request.append("id", this.editSyllabusRequest.id);
            request.append("parentClass", this.editSyllabusRequest.parentClass);
            request.append("classId", this.editSyllabusRequest.classId);
            request.append("lessonId", this.editSyllabusRequest.lessonId);
            request.append("teacherId", this.editSyllabusRequest.teacherId);
            request.append("date", this.editSyllabusRequest.date);
            request.append("startTime", this.editSyllabusRequest.startTime);
            request.append("endTime", this.editSyllabusRequest.endTime);

            this.$http.put("Branch/UpdateSyllabus", request).then((data) => {
                    this.getSyllabusData();
                    this.editPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        removeSyllabus() {
            this.$confirm({
                title: 'Onayla',
                message: `Onaylamak İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Onayla'
                },
                callback: async (confirm) => {
                    if (confirm) {
                        await this.$http.delete("Branch/DeleteSyllabus/" + this.editSyllabusRequest.id);
                        this.getSyllabusData();
                        this.editPanel = false;
                    }
                }
            });

        },
        eventClassName: function (arg) {

            return [
                // Background Color
                `bg-light-primary`,
            ]

        },
        handleEvent: function (arg) {
            var event = arg.event;
            var data = this.oldData.filter(x => x.id == event.id);
            if (data.length > 0)
                data = data[0];
            //console.log(data);
            this.editSyllabusRequest.id = data.id;
            this.editSyllabusRequest.classId = data.class.id;
            this.editSyllabusRequest.lessonId = data.lesson.id;
            this.editSyllabusRequest.teacherId = data.teacher.id;
            this.editSyllabusRequest.date = data.date;
            this.editSyllabusRequest.startTime = data.startTime;
            this.editSyllabusRequest.endTime = data.endTime;
            this.editPanel = true;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style><style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
